<template>
  <div class="container-box">
    <PageHeader :navData="navData"></PageHeader>
    <div :class="['title-url']">
      <div class="title">
        <span @click="goHome()">{{ $t('Home') }}</span>
        <span class="line"></span>
        <span>{{ $t('Solutions') }}</span>
      </div>
    </div>
    <div class="info-panel-box">
      <div class="info-panel" v-for="item in pageData" :key="item.id">
        <div class="info-title">
          <!-- <img src="@/assets/img/gxLogo.png" alt="" /> -->
          <h3>{{ item.caseTitle }}</h3>
        </div>
        <div class="info-content">
          <div class="info-text">
            <p>{{ item.caseText }}</p>
          </div>
          <div
            v-if="item.caseImgExtList || item.caseVideoExtList"
            class="info-media"
          >
            <img
              v-for="(imgPath, index) in item.caseImgExtList"
              :key="index"
              :src="imgPath"
            />
            <video
              v-for="(videoPath, index) in item.caseVideoExtList"
              :key="index"
              :src="videoPath"
              controls
            ></video>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'IndustrySolutions',
  data() {
    return {
      // 导航数据
      navData: {
        navigationDescribe: '',
        navigationImg: '',
        navigationName: ''
      },
      pageData: ''
    }
  },
  created() {
    this.getPageData()
    this.getNavImg()
  },
  methods: {
    getPageData() {
      this.$axios.get('/officialweb/data/listCaseInfoV2').then((res) => {
        if (res.code == 0 && res.data) {
          let data = res.data
          this.pageData = data
        }
      })
    },
    // 获取导航信息
    getNavImg() {
      this.$axios
        .post('/officialweb/data/getGwNavigation', {
          navigationType: '3'
        })
        .then((res) => {
          this.navData.navigationDescribe = res.data.navigationDescribe
          this.navData.navigationImg = res.data.navigationImg
          this.navData.navigationName = res.data.navigationName
        })
        .catch(() => {})
    },
    // 返回首页
    goHome() {
      this.$router.push({ path: '/' })
    }
  }
}
</script>

<style lang="less" scoped>
.container-box {
  width: 100%;
  height: 100%;
}
.info-panel-box {
  width: @mainSize;
  padding: 30px 0px;
  margin: 0 auto;
  background-color: #fff;
}
.info-panel {
  width: 100%;
  // background-color: @lightGrayColor;
  margin-bottom: 50px;
  &:last-child {
    margin-bottom: 0px;
  }
  .info-title {
    // color: #fff;
    color: @blueColor;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    letter-spacing: 2px;
    box-sizing: border-box;
    // background-color: @blueColor;
    img {
      height: 30px;
      margin-right: 10px;
    }
    h3 {
      margin: 0;
      line-height: 60px;
      font-size: @titleSize;
      position: relative;
      font-weight: 700;

      &::after {
        content: '';
        display: block;
        width: 100px;
        height: 5px;
        position: absolute;
        left: 50%;
        bottom: 0;
        transform: translateX(-50%);
        background-color: @mainColor;
      }
    }
  }
  .info-content {
    width: 100%;
    padding: 20px;
    overflow: hidden;
    // background-color: @lightBlueColor;
    .info-text {
      // text-align: left;
      // text-indent: 2em;
      padding: 10px;
      margin-top: 10px;
      margin-bottom: 20px;
      // border-bottom: 1px dashed @mainColor;
    }
    .info-media {
      width: 80%;
      margin: 0px auto 30px;
      border-radius: 10px;
      overflow: hidden;
      &:last-child {
        margin-bottom: 0px;
      }
      video,
      img {
        width: 100%;
        min-height: 200px;
        margin-bottom: 10px;
        object-fit: cover;
        border-radius: 10px;
        border: 1px solid #eee;
        &:last-child {
          margin-bottom: 0px;
        }
      }
    }
  }
}
</style>
